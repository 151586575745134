<template>
  <v-col cols="12" sm="3" md="3" class="mt-0 pt-0 mb-0 pb-0">
    <v-card class="mt-0 pt-0 mb-0 pb-0" max-width="400">
       
      <v-data-table
        dense
        :headers="santralHeaders"
        :items="santralItems"
        hide-default-header
        hide-default-footer
        class="elevation-1"
      />
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ["selectedsantral"],
  data: () => ({
    santralItems: [],
    componentKey: 1,
  }),
  computed: {
    santralHeaders() {
      return [
        { text: "type", value: "type" },
        { text: "value", value: "value" },
      ];
    },
  },
  watch: {
    selectedsantral() {
      if (this.selectedsantral !== null && this.selectedsantral !== undefined) {
        this.setInverterQuantity();
        this.setStringsQuantity();
        this.setSensorQuantity();
      }
    },
  },
  created() {
    this.santral_items = [
      {
        type: "İnverter Sayısı",
        value: 0,
      },
      {
        type: "Strings Sayısı",
        value: 0,
      },
      {
        type: "Modül Sayısı",
        value: 0,
      },
      {
        type: "Sensor Sayısı",
        value: 0,
      },
      {
        type: "Güncelleme zamanı",
        value: "",
      },
    ];
  },
  mounted() {
    const self = this;
    this.componentKey += 1;
    let k = 0;

    // eslint-disable-next-line no-use-before-define
    const myVarİnv = setInterval(myTimerİnv, 1000);
    function myTimerİnv() {
      // eslint-disable-next-line no-plusplus
      k++;
      if (k > 30) {
        clearInterval(myVarİnv);
      }
      console.log(self.selectedsantral)

      if (self.selectedsantral !== undefined) {
        clearInterval(myVarİnv);
        self.setInverterQuantity();
      }
    }

    // eslint-disable-next-line no-use-before-define
    const myVarInvMod = setInterval(myTimerİnvMod, 1000);
    let k1 = 0;
    function myTimerİnvMod() {
      // eslint-disable-next-line no-plusplus
      k1++;
      if (k1 > 30) {
        clearInterval(myVarInvMod);
      }
      const { inverterData } = self.$store.getters;
      console.log(inverterData)

      if (
        Object.keys(inverterData).length > 0 &&
        self.selectedsantral !== undefined
      ) {
        clearInterval(myVarInvMod);
        self.setInverterQuantity();
      }
    }
    let k2 = 0;
    // eslint-disable-next-line no-use-before-define
    const myVarString = setInterval(myTimerString, 1000);
    function myTimerString() {
      // eslint-disable-next-line no-plusplus
      k2++;
      if (k2 > 30) {
        clearInterval(myVarString);
      }
      const { stringboxData } = self.$store.getters;

      if (
        Object.keys(stringboxData).length > 0 &&
        self.selectedsantral !== undefined
      ) {
        clearInterval(myVarString);
        self.setStringsQuantity();
      }
    }

    // eslint-disable-next-line no-use-before-define
    const myVarSensor = setInterval(myTimerSensor, 1000);
    let k3 = 0;
    function myTimerSensor() {
      // eslint-disable-next-line no-plusplus
      k3++;
      if (k3 > 30) {
        clearInterval(myVarSensor);
      }
      const { santralSensorData } = self.$store.getters;
      if (
        Object.keys(santralSensorData).length > 0 &&
        self.selectedsantral !== undefined
      ) {
        clearInterval(myVarSensor);
        self.setSensorQuantity();
      }
    }

    this.santralgeneralupdate();
    setInterval(this.santralgeneralupdate, 300000);
  },
  methods: {
    santralgeneralupdate() {
      if (this.santral_items.length > 0) {
        this.santral_items[4].value = this.$store.getters.santralgeneralupdate;
      }
    },

    setInverterQuantity() {
      const { santralInverterData } = this.$store.getters;

      let inverterQuantity = 0;
      if (
        Object.keys(santralInverterData).length > 0 &&
        this.santral_items.length > 0 &&
        this.selectedsantral !== undefined
      ) {
        const invLengh = Object.keys(
          santralInverterData[this.selectedsantral]
        ).length;
        // eslint-disable-next-line radix
        inverterQuantity += parseInt(invLengh);
        this.santral_items[0].value = inverterQuantity;
      }
    },
    setStringsQuantity() {
      const { santralStringboxData } = this.$store.getters;

      let combinerQuantity = 0;
      let stringsQuantity = 0;
      if (Object.keys(santralStringboxData).length > 0) {
        const cLen = Object.keys(
          santralStringboxData[this.selectedsantral]
        ).length;
        // eslint-disable-next-line radix
        combinerQuantity += parseInt(cLen);
        Object.keys(santralStringboxData[this.selectedsantral]).forEach(
          (item) => {
            const sLen =
              santralStringboxData[this.selectedsantral][item].stringQuantity;
            // eslint-disable-next-line radix
            stringsQuantity += parseInt(sLen);
          }
        );

        this.santral_items[1].value = combinerQuantity;
        this.santral_items[2].value = stringsQuantity;
      }
    },

    setSensorQuantity() {
      const { santralSensorData } = this.$store.getters;

      let sensorQuantity = 0;
      if (Object.keys(santralSensorData).length > 0) {
        const sLenght = Object.keys(
          santralSensorData[this.selectedsantral]
        ).length;
        // eslint-disable-next-line radix
        sensorQuantity += parseInt(sLenght);

        this.santral_items[3].value = sensorQuantity;
      }
    },
  },
};
</script>