<template>
  <v-col
    cols="12"
    sm="9"
    md="9"
    class="mt-0 pt-0 mb-0 pb-0 ml-0 mr-0 pl-0 pr-0"
  >
    <v-card class="mt-0 pt-0 mb-0 pb-0 ml-0 mr-0 pl-0 pr-0">
      <v-data-table
        :id="tableId"
        dense
        :headers="production_header"
        :items="items"
        hide-default-footer
        class="elevation-0"
      />
    </v-card>
  </v-col>
</template>

<script>
import * as Santral from '@/api/Dashboard/Santral';
import i18n from '@/locale';

export default {
  props: ['selectedsantral'],
  data: () => ({
    items: [],
    tableId: 'selected-santral-stats-data',
  }),
  computed: {
    production_header() {
      return [
        {
          text: i18n.t('general.date'),
          value: 'date',
          width: '100',
          sortable: false,
        },
        {
          text: `${i18n.t('TotalEnergy')}(MW)`,
          value: 'DailyEnergy',
          sortable: false,
        },
        {
          text: `${i18n.t('TheoreticalPower')}(MW)`,
          value: 'TheoreticalPower',
          sortable: false,
        },
        {
          text: `${i18n.t('PerformanceRatio')}(%)`,
          value: 'PerformanceRatio',
          sortable: false,
        },
        {
          text: `${i18n.t('Price')} ($)`,
          value: 'Price',
          sortable: false,
        },
        {
          text: i18n.t('EquivalentTrees'),
          value: 'EquivalentTrees',
          sortable: false,
        },
        {
          text: i18n.t('CO2Avoided'),
          value: 'CO2Avoided',
          sortable: false,
        },
        {
          text: i18n.t('BarrelsofOil'),
          value: 'BarrelsofOil',
          sortable: false,
        },
      ];
    },
  },

  watch: {
    selectedsantral() {
      if (this.selectedsantral !== null && this.selectedsantral !== undefined) {
        this.getTableData();
      }
    },
  },
  created() {},
  mounted() {
    // eslint-disable-next-line no-use-before-define
    setInterval(this.getTableData, 300000);
  },
  methods: {
    getTableData() {
      const promise = new Promise((r) => {
        const StatsMeasure = this.$store.getters.statsmeasureList;
        r(StatsMeasure);
      });
      promise.then((StatsMeasure) => {
        const measureList = [];
        const measures = [
          'CO2Avoided',
          'BarrelsofOil',
          'EquivalentTrees',
          'Price',
        ];

        StatsMeasure.forEach((item) => {
          measures.push(item.value);
          measureList.push(item);
        });

        const tempData = Santral.default.getSantralStatsData(
          measures,
          this.selectedsantral,
        );
        tempData.then((res) => {
          this.items = res;
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
