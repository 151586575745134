<template>
  <v-col cols="12">
    <v-card class="d-flex">
      <v-col class="col-1 mb-0 pb-0 mt-4 mb-4 pt-0 mr-10">
        <v-dialog v-model="dialog" scrollable max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
              Santral Seç
            </v-btn>
          </template>

          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" />
          </v-overlay>
          <v-card>
            <v-card-title>Santral Listesi </v-card-title>
            <v-divider />
            <v-card-text style="height: 300px">
              <v-radio-group v-model="santralModel">
                <v-radio
                  v-for="santral in santralList"
                  :key="santral"
                  :label="santral"
                  :value="santral"
                  s
                />
              </v-radio-group>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Kapat
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <div class="mt-2 ml-2">
        <notify-modal :dialog="alarmDialog" />
      </div>

      <v-col class="col-2 mb-0 pb-0 mt-0 pt-4 d-flex">
        <h4>Santral: {{ santralModel }}</h4>
      </v-col>

      <v-spacer />
    </v-card>
  </v-col>
</template>

<script>
import NotifyModal from "@/views/components/Notification/NotifyModal.vue";

export default {
  components: {
    NotifyModal,
  },
  data: () => ({
    santralModel: "",
    alarmDialog: false,
    dialog: false,
    overlay: false,
    loading: false,
  }),
  computed: {
    santralList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      const { santral } = this.$store.getters.selectedSantralModel;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralModel = santral;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },
  },
  watch: {
    santralModel() {
      if (this.santralModel !== undefined) {
        this.$emit("selectedsantral", this.santralModel);
      }
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>
