<template>
  <div>
    <SantralSelect @selectedsantral="selectedSantralFunc" />

    <InstantDataGauge :selectedsantral="santralmodel" />

    <v-col class="d-flex col-12">
      <SantralStats :selectedsantral="santralmodel" />
      <SantralInfo :selectedsantral="santralmodel" />
    </v-col>

    <DailyMinuteData :selectedsantral="santralmodel" />
  </div>
</template>

<script>
import SantralSelect from "@/views/components/Santrals/SantralSelect.vue";
import InstantDataGauge from "@/views/components/Santrals/InstantDataGauge.vue";
import SantralStats from "@/views/components/Santrals/SantralStats.vue";
import DailyMinuteData from "@/views/components/Santrals/DailyMinuteData.vue";
import SantralInfo from "@/views/components/Santrals/SantralInfo.vue";

export default {
  components: {
    SantralSelect,
    InstantDataGauge,
    SantralStats,
    DailyMinuteData,
    SantralInfo,
  },
  data: () => ({
    santralmodel: "null",
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    selectedSantralFunc(item) {
      console.log(item);

      this.santralmodel = item;
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
