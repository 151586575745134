<template>
  <v-col cols="12" class="">
    <v-card>
      <v-row>
        <v-spacer />

        <v-col cols="3" sm="2" md="2" class="pr-3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Tarih"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              />
            </template>
            <v-date-picker v-model="startDate" no-title scrollable>
              <v-spacer />
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(startDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="1" sm="1" md="1" class="pr-3">
          <v-select
            v-model="chartTypeModel"
            :items="chartType"
            label="Grafik"
            hide-details
          />
        </v-col>
        <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
          <v-btn color="primary" @click="getSantralDataChart"> Filtrele </v-btn>
        </v-col>
      </v-row>

      <v-col class="col-12 mt-0 pt-0 pb-0 mb-0">
        <echart
          id="santral-minute-data-chart"
          class-name="widget-content"
          height="340px"
          :width="windowsWidth"
          :chart-data="chartData"
        />
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import Echart from "@/views/components/Echart/Chart.vue";
import * as Santral from "@/api/Santral/minute";
import moment from "moment";
import i18n from "@/locale";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  props: ["selectedsantral"],
  data: () => ({
    reportTypeModel: "Minute",
    componentKey: 1,
    chartData: {},
    chartTypeModel: "line2",
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),

    chartType: ["line", "bar", "line2"],

    menu: false,
    modal: false,
    menu2: false,
    riseze: "false",
    measures: [],
    windowsWidth: `${document.documentElement.clientWidth - 100}px`,
  }),
  computed: {},
  watch: {
    selectedsantral() {
      if (this.selectedsantral !== null && this.selectedsantral !== undefined) {
        this.getSantralDataChart();
      }
    },
    $route(from, to) {
      this.riseze = "true";
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.getSantralDataChart();
    }, 1000);
    setInterval(this.getSantralDataChart, 300000);
  },

  methods: {
    async getSantralDataChart() {
      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      this.chartText =
        `${this.selectedsantral} santrali genel istatistik`.toUpperCase();

      const measureList = [];
      const measures = [];

      const energy = ["DailyEnergy", "TotalEnergy"];
      const tempmeasureList = [
        {
          value: "IstantaneousPower",
          text: i18n.t("IstantaneousPower"),
          type: "float",
          unit: "Kw",
        },
        {
          value: "PerformanceRatio",
          text: i18n.t("PerformanceRatio"),
          type: "float",
          unit: "%",
        },

        {
          value: "TheoreticalPower",
          text: i18n.t("TheoreticalPower"),
          type: "float",
          unit: "kW",
        },
      ];
      tempmeasureList.forEach((item) => {
        if (energy.indexOf(item.value) < 0) {
          measures.push(item.value);
          measureList.push(item);
        }
      });

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.selectedsantral,
          measures,
        },
      };

      const self = this;
      const promise = new Promise((r) => {
        const { selectedFarm } = this.$store.getters;
        r(selectedFarm);
      });
      promise.then((selectedFarm) => {
        const chartText = "";

        const tempData = Santral.default.getSantralMinuteDataDash(
          params,
          measureList,
          chartText,
          this.chartTypeModel,
          selectedFarm
        );
        tempData.then((res) => {
          self.chartData = res;
          self.componentKey += 1;
          self.forceRerender();
        });
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
